<template>
    <wizard-layout>
        <wizard-payment
            @goToNextStep="$emit('goToNextStep')"
        />
    </wizard-layout>
</template>

<script>
import WizardPayment from '@/components/wizard/WizardPayment';

export default {
    name: 'Index',
    metaInfo: {
        title: 'Wizard General Info'
    },
    components: {
        WizardPayment
    }
}
</script>
