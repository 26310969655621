<template>
    <div class="wizard-payment__radio">
        <!-- <form-input-radio
            text="PayPal"
            data="paypal"
            name="paypal"
            :is-checked="type === 'paypal'"
            @input="onChange($event)"
        /> -->
        <form-input-radio
            text="Payoneer"
            data="payoneer"
            name="payoneer"
            :is-checked="type === 'payoneer'"
            @input="onChange($event)"
        />
        <form-input-radio
            text="Wire transfer"
            data="wire"
            name="wire"
            :is-checked="type === 'wire'"
            @input="onChange($event)"
        />
    </div>
</template>

<script>
export default {
    name: 'WizardPaymentRadioButtons',
    props: {
        value: String
    },
    data() {
        return {
            type: 'payoneer'
        }
    },
    watch: {
        value(val) {
            this.type = val
        }
    },
    methods: {
        onChange(event) {
            this.$emit('update:field', event);
            this.$emit('onChange', event)
        }
    }
}
</script>

<style lang="scss">
    .wizard-payment__radio {
        display: flex;
        margin-bottom: 40px;
        .form-radio {
            margin-right: 24px;
            &:last-child {
                margin-right: 0;
            }
            &_label {
                align-items: center;
            }
        }
        & + .wizard-form {
            .form-input__fieldset:last-child {
                margin-bottom: 30px;
            }
        }
    }
    @media screen and (max-width: 576px){
        .form-radio {
            margin: 10px 0;
        }
        .wizard-payment__radio {
            margin-bottom: 20px;
        }
    }
</style>
