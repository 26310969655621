import {
    required
    // minLength
    // requiredIf
} from 'vuelidate/lib/validators';

export const validationRules = {
    paypal_email: {
        required
    }
};
