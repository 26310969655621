<template>
    <div class="wizard-form">
        <form>
            <form-input
                v-model="$v.paypal_email.$model"
                legend="Paypal Email"
                :field.sync="paypal_email"
                :errors="validationMessage($v.paypal_email)"
                :is-valid="$v.paypal_email.$dirty && !$v.paypal_email.$anyError"
                @input.native="$v.paypal_email.$touch()"
                @blur.native="$v.paypal_email.$touch()"
            />
            <!--<form-input />-->
            <!--<form-textarea />-->
        </form>
    </div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { validationMessage } from 'vuelidate-messages'
import formValidationMixin from '@/mixins/formValidationMixin.js'
import { validationRules } from '@/validation/wizard/wizard-payment/paypal/Rules.js'
import { formMessages } from '@/validation/wizard/wizard-payment/paypal/Messages.js'
import { eventBus } from '@/helpers/event-bus';

import { mapWriterFields } from '@/store/modules/writer'

export default {
    name: 'WizardPaymentPayPal',
    mixins: [
        validationMixin,
        formValidationMixin
    ],
    validations: {
        ...validationRules
    },
    computed: {
        ...mapWriterFields([
            'writer_data.paypal_email'
        ])
    },
    mounted() {
        eventBus.$on('saveSettings', () => {
            this.validate()
        })
    },
    beforeDestroy() {
        eventBus.$off('saveSettings')
    },
    methods: {
        validationMessage: validationMessage(formMessages)
    }
}
</script>
