<template>
    <div class="wizard-payment">
        <div class="wizard__title">
            Withdrawal Method
        </div>
        <div class="wizard__form">
            <wizard-payment-radio-buttons
                v-model="type"
                :field.sync="type"
            />
            <wizard-payment-pay-pal
                v-if="type === 'paypal'"
                ref="paypal"
            />
            <wizard-payment-payoneer
                v-if="type === 'payoneer'"
                ref="payoneer"
            />
            <!-- <wizard-payment-transfer
                v-if="type === 'wire'"
                ref="wire"
            /> -->
            <custom-button
                default
                @on-btn-click="validateForm"
            >
                next
            </custom-button>
        </div>
    </div>
</template>

<script>
import { mapWriterFields } from '@/store/modules/writer'
import { createNamespacedHelpers } from 'vuex';
import {
    ACTION_UPDATE_PAYMENT_DETAILS
} from '@/store/modules/writer/action-types'
import WizardPaymentRadioButtons from './components/WizardPaymentRadioButtons';
import WizardPaymentPayoneer from './components/WizardPaymentPayoneer';
import WizardPaymentPayPal from './components/WizardPaymentPayPal';
// import WizardPaymentTransfer from './components/WizardPaymentTransfer';

const { mapActions: mapWriterActions } = createNamespacedHelpers('writer')

export default {
    name: 'Index',
    components: {
        WizardPaymentRadioButtons,
        WizardPaymentPayoneer,
        WizardPaymentPayPal
        // WizardPaymentTransfer
    },
    data() {
        return {
            type: 'paypal'
        }
    },
    computed: {
        ...mapWriterFields([
            'writer_data'
        ])
    },
    methods: {
        ...mapWriterActions([
            ACTION_UPDATE_PAYMENT_DETAILS
        ]),
        async validateForm() {
            await this.$refs[this.type].validateWithoutSubmit()
            const isValid = await this.$refs[this.type].isValid();
            if (isValid) {
                try {
                    this.ACTION_UPDATE_PAYMENT_DETAILS({
                        pay_method: this.type,
                        ...this[this.type]
                    })
                    await this.$emit('goToNextStep')
                } catch (e) {
                    console.error(e)
                }
            }
        }
    }
}
</script>
